

export default function MobilePage(){
    return(
        <div className="container">

        
        <h1 className="header">
            Sorry! This website isn't available for mobile devices right now.
            A mobile version of this software is in the works however.
        </h1>

        </div>
    )
}